<template>
  <div class="cancel">
    <h2 class="cancel__title">Trước khi bạn rời đi...</h2>
    <h3 class="cancel__subtitle">Chúng tôi vẫn còn rất nhiều nội dung đa dạng khác đang chờ bạn. </h3>
    <div class="cancel__slider">
      <img class="cancel__slider__img" :src="imgs[currentNum]" />
      <img class="cancel__slider__subimg--left" :src="currentNum > 0 ? imgs[currentNum - 1] : imgs[imgs.length - 1]" />
      <img class="cancel__slider__subimg--right" :src="currentNum < imgs.length - 1 ? imgs[currentNum + 1] : imgs[0]" />
      <div class="cancel__slider__carousel">
        <div :class="currentNum === n ? 'cancel__slider__carousel__area--active' : 'cancel__slider__carousel__area'"
        v-for="(img, n) in imgs" :key="n" @click="currentNum = n"></div>
      </div>
    </div>
    <button class="cancel__btn" @click="$router.push({ name: 'Home' })">Quay về Trang chủ</button>
    <router-link class="cancel__link" :to="{ name: 'MenuCancelReason' }">Hủy bỏ thành viên</router-link>
  </div>
</template>

<script>
export default {
  components: { },
  data () {
    return {
      // 表示する画像一覧
      imgs: [require('@/assets/img/cancel1.jpg'), require('@/assets/img/cancel2.jpg'), require('@/assets/img/cancel3.jpg')],
      // 現在表示している画像の枚数
      currentNum: 0
    }
  },
  mounted () {
    // 2秒ごとに画像を切り替える
    setInterval(() => {
      if (this.currentNum + 1 === this.imgs.length) {
        this.currentNum = 0
      } else {
        this.currentNum++
      }
    }, 2000)
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.cancel {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  text-align: center;
  &__title {
    margin: 0;
    margin-top: 50px;
    font-size: 25px;
    font-weight: bold;
    color: #393939;
  }
  &__subtitle {
    width: 70%;
    margin: 0;
    margin-left: 15%;
    font-size: 16px;
    font-weight: normal;
    color: #707070;
  }
  &__slider {
    position: relative;
    width: 100%;
    margin-top: 40px;
    text-align: center;
    &__img {
      height: 120px;
      width: auto;
    }
    &__subimg {
      position: absolute;
      top: 15px;
      height: 90px;
      width: auto;
      &--left {
        @extend .cancel__slider__subimg;
        left: calc((100% - 213px - 90px) / 2 - 120px);
      }
      &--right {
        @extend .cancel__slider__subimg;
        right: calc((100% - 213px - 90px) / 2 - 120px);
      }
    }
    &__carousel {
      width: 100%;
      text-align: center;
      &__area {
        display: inline-block;
        vertical-align: middle;
        height: 6px;
        width: 6px;
        margin: 0 4px;
        border-radius: 50%;
        background-color: #707070;
        &--active {
          @extend .cancel__slider__carousel__area;
          background-color: #ff9534;
        }
      }
    }
  }
  &__btn {
    height: 44px;
    width: 231px;
    margin-top: 40px;
    font-size: 17px;
    color: white;
    border-radius: 36px;
    background-color: #ff9534;
  }
  &__link {
    display: block;
    margin-top: 20px;
    font-size: 17px;
    font-weight: bold;
    color: #707070;
  }
}
</style>
